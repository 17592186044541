import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get';
import { RichText } from 'prismic-reactjs';
import { linkResolver } from '../utils/linkResolver';
import Card from '../components/card'


const CardDeck = ({ slice }) => {
  var gridClass = 'lg:col-span-4'
  if (get(slice.primary, 'deckTitle') && slice.primary.deckTitle[0].text !== '') {
    gridClass = 'lg:col-span-3'
  }
  return (
    <div className={`component-${slice.type}`}>
      {get(slice.primary, 'deckTitle') && slice.primary.deckTitle[0].text !== '' && (
        <div className='deck-title title'>
          <h1 className='leading-tight'>{RichText.asText(get(slice.primary, 'deckTitle'), linkResolver)}</h1>
        </div>
      )}
      <ul className={`col-span-4 sm:flex flex-wrap ${gridClass}`}>
        {slice.fields.map((card, index) => {
          return (
            <React.Fragment key={`card-${index}`}>
              <Card fields={card} />
            </React.Fragment>
          )
        })}
      </ul>
    </div>
  )
}

CardDeck.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default CardDeck
