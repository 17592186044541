import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get';
import { RichText } from 'prismic-reactjs';
import { linkResolver } from '../utils/linkResolver';


const Quote = ({ slice }) => {
  return (
    <blockquote className={`component-${slice.type} relative`}>
      {get(slice.primary, 'image') &&
        <svg className='transform -translate-x-8 -translate-y-3 absolute h-8 left-0 text-blue-500 opacity-75 top-0 w-8' fill='currentColor' viewBox='0 0 32 32'>
          <path d='M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z'></path>
        </svg>
      }
      <div className='my-4 text-3xl relative leading-tight font-semibold'>
        {RichText.render(get(slice.primary, 'quote'), linkResolver)}
      </div>
      {get(slice.primary, 'image') &&
        <footer className='flex items-center justify-center'>
          <img className='bg-white border-2 border-blue-500 mr-4 rounded-full w-16 h-16' src={get(slice.primary.image, 'url')} alt={get(slice.primary.image, 'alt')} />
          <div>
            <p className='text-blue-800 font-semibold leading-none text-sm mb-1'>{get(slice.primary, 'author')}</p>
            <p className='text-blue-700 text-xs'>{get(slice.primary, 'role')} / {get(slice.primary, 'company')}</p>
          </div>
        </footer>
      }
    </blockquote>
  )
}

Quote.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default Quote
