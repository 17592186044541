import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get';


const Image = ({ slice }) => {
  return (
    <figure className='relative'>
      <img className='mx-auto' src={get(slice.primary.image, 'url')} alt={get(slice.primary.image, 'alt','')} />
      <figcaption className='absolute font-semibold text-xs md:text-xl bottom-0 left-0 p-2 text-white bg-blue-700 leading-none'>{get(slice.primary.image, 'alt')}</figcaption>
    </figure>
  )
}

Image.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default Image
