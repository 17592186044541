import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get';
import { Link } from 'gatsby'
import { linkResolver } from '../utils/linkResolver';
import Text from '../components/text'


const TextWithButton = ({ slice }) => {
  var link = get(slice.primary.button_link, 'url',(linkResolver(get(slice.primary.button_link, '_meta',[]),[])))
  return (
    <React.Fragment>
      <div className={`component-${slice.type}`}>
        <Text slice={slice} />
        <div className='mt-5 md:mt-8 flex justify-center max-w-4xl mx-auto'>
          {get(slice.primary.button_link, 'url') ? (
            <a className='button' href={link}>{get(slice.primary, 'button_text')}</a>
          ):(
            <Link className='button' to={link}>{get(slice.primary, 'button_text')}</Link>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

TextWithButton.propTypes = {
  slice: PropTypes.object.isRequired
}

export default TextWithButton
