import React from 'react'
import { graphql } from 'gatsby'
import Contact from '../components/contact'
import Layout from '../components/layout'
import SEO from '../components/seo'
import SliceList from '../components/sliceList'


export const query = graphql`
  query pageQuery($uid: String) {
    prismic {
      allPages(uid: $uid) {
        edges {
          node {
            _meta {
              uid
            }
            title
            meta_title
            meta_keywords
            meta_description
            body {
              ... on PRISMIC_PageBodyText {
                type
                primary {
                  text
                }
              }
              ... on PRISMIC_PageBodyCard_deck {
                type
                primary {
                  deckTitle
                }
                fields {
                  image
                  text
                  title
                  button_text
                  button_link {
                    ... on PRISMIC_Page {
                      _meta {
                        uid
                        type
                        tags
                        lang
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                    ... on PRISMIC__FileLink {
                      name
                      url
                      size
                    }
                  }
                }
              }
              ... on PRISMIC_PageBodyText_with_button {
                type
                primary {
                  text
                  button_text
                  button_link {
                    _linkType
                    ... on PRISMIC_Page {
                      _meta {
                        uid
                        type
                        tags
                        lang
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                    ... on PRISMIC__FileLink {
                      name
                      url
                      size
                    }
                  }
                }
              }
              ... on PRISMIC_PageBodyQuote {
                type
                primary {
                  role
                  quote
                  image
                  company
                  author
                }
              }
              ... on PRISMIC_PageBodyHero {
                type
                primary {
                  image
                  title
                  text
                  button_text
                  button_link {
                    _linkType
                    ... on PRISMIC_Page {
                      _meta {
                        uid
                        type
                        tags
                        lang
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                    ... on PRISMIC__FileLink {
                      name
                      url
                      size
                    }
                  }
                }
              }
              ... on PRISMIC_PageBodyBanner_with_caption {
                type
                primary {
                  image
                  title
                  text
                  button_text
                  button_link {
                    _linkType
                    ... on PRISMIC_Page {
                      _meta {
                        uid
                        type
                        tags
                        lang
                      }
                    }
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                    ... on PRISMIC__FileLink {
                      name
                      url
                      size
                    }
                  }
                }
              }
              ... on PRISMIC_PageBodyImage {
                type
                primary {
                  image
                }
              }
              ... on PRISMIC_PageBodyVideo {
                type
                label
                primary {
                  embed_video
                  video_link {
                    ... on PRISMIC__FileLink {
                      name
                      url
                      size
                    }
                  }
                  video_poster
                  video_title
                }
              }
            }
          }
        }
      }
    }
  }
`


const RenderBody = ({ page }) => {
  return (
    <>
      {page.body !== null && page.body.length > 0 && page.body.map((slice, index) => {
        return (
          <React.Fragment key={`slice-${index}`}>
            <SliceList slice={slice} />
          </React.Fragment>
        )
      })}
      {page._meta.uid === 'contact' && (
        <div className='max-w-screen-md mx-auto pb-8 px-4 md:pb-16'>
          <Contact />
        </div>
      )}
    </>
  )
}

const page = props => {
  const doc = props.data.prismic.allPages.edges.slice(0,1).pop();
  if(!doc) return null;

  return(
    <Layout>
      <SEO title={doc.node.meta_title ? (doc.node.meta_title):(doc.node.title)} keywords={doc.node.meta_keywords} description={doc.node.meta_description} />
      <RenderBody page={doc.node} />
    </Layout>
  )
}
export default page
