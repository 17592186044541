import React from 'react'

const Contact = () => {
  return (
    <div>
      <form
        name='contact'
        method='POST'
        action='/success'
        data-netlify='true'
        data-netlify-honeypot='bot-field'
        className='col-span-2'
      >
        <input type='hidden' name='bot-field' />
        <input type='hidden' name='form-name' value='contact' />
        <div className='mb-4'>
          <label className='block font-semibold text-xl tracking-tighter mt-5 mb-3' htmlFor='name'>What's your name?</label>
          <input className='shadow-sm appearance-none border rounded-md w-full py-3 px-5 text-xl text-gray-700 leading-tight focus:outline-none focus:shadow-outline' type='text' name='name' id='name' required />
        </div>
        <div className='mb-4'>
          <label className='block font-semibold text-xl tracking-tighter mt-5 mb-3' htmlFor='name'>What's your company name?</label>
          <input className='shadow-sm appearance-none border rounded-md w-full py-3 px-5 text-xl text-gray-700 leading-tight focus:outline-none focus:shadow-outline' type='text' name='company-name' id='name' />
        </div>
        <div className='mb-4'>
          <label className='block font-semibold text-xl tracking-tighter mt-5 mb-3' htmlFor='email'>What's your email address?</label>
          <input className='shadow-sm appearance-none border rounded-md w-full py-3 px-5 text-xl text-gray-700 leading-tight focus:outline-none focus:shadow-outline' type='email' name='email' id='email' required />
        </div>
        <div className='mb-4'>
          <label className='block font-semibold text-xl tracking-tighter mt-5 mb-3' htmlFor='message'>How can we help?</label>
          <textarea className='shadow-sm appearance-none border rounded-md w-full py-3 px-5 text-xl text-gray-700 leading-tight focus:outline-none focus:shadow-outline' name='message' id='message' required></textarea>
        </div>
        <button type='submit' className='button'>Send your enquiry</button>
      </form>
    </div>
  )
}

export default Contact
