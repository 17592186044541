import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get';
import { Link } from 'gatsby'
import { RichText } from 'prismic-reactjs';
import { linkResolver } from '../utils/linkResolver';
import ResponsiveImage from '../components/responsiveImage'

const Card = ({ fields }) => {
  var link = get(fields.button_link, 'url',(linkResolver(get(fields.button_link, '_meta',[]),[])))

  return (
    <li className='sm:w-1/2 md:w-1/3 sm:flex'>
      <div className='component-card bg-white rounded-md overflow-hidden flex flex-col flex-1 relative transform transition duration-300 hover:scale-105 shadow-sm hover:shadow ease-in-out m-2'>
        {get(fields.image, 'url') && (
          <ResponsiveImage image={get(fields, 'image')} sizes='(min-width: 640px) calc(50vw - 2rem), (min-width: 1024px) calc(25vw - 2rem), (min-width: 1280px) 600px, calc(50vw - 1.5rem)' />
        )}
        <div className='flex flex-col flex-grow m-4'>
          <div className='text-2xl leading-none font-semibold text-blue-800'>{RichText.render(get(fields, 'title'), linkResolver)}</div>
          <div className='mb-3 text leading-6'>
            {RichText.render(get(fields, 'text'), linkResolver)}
          </div>
          {link !== '/undefined' && (
            <div className='mt-auto'>
              {get(fields.button_link, 'url') ? (
                <a className='button link' href={link}>{get(fields, 'button_text')}</a>
              ):(
                <Link className='button link' to={link}>{get(fields, 'button_text')}</Link>
              )}
            </div>
          )}
        </div>
      </div>
    </li>
  )
}

Card.propTypes = {
  fields: PropTypes.object.isRequired,
}

export default Card
