import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { Link } from 'gatsby'
import { RichText } from 'prismic-reactjs';
import { linkResolver } from '../utils/linkResolver';


const Hero = ({ author, publishDate, slice }) => {
  var link = get(slice.primary.button_link, 'url',(linkResolver(get(slice.primary.button_link, '_meta',[]),[])))

  return (
    <React.Fragment>
      <div className={`relative bg-white text-blue-800 text-white overflow-hidden component-${slice.type}`}>
        <div className=''>
          <div className='lg:flex flex-row max-w-screen-xl mx-auto'>
            <div className='w-full flex-1 px-4 sm:px-6 mt-10 sm:my-12 md:my-16 lg:my-200 xl:my-28'>
              <div className='text-center lg:text-left'>
                <div className='title'>{RichText.render(get(slice.primary, 'title'), linkResolver)}</div>
                {get(slice.primary, 'text') && <div className='text-xl sm:text-2xl my-3 leading-7'>{RichText.render(get(slice.primary, 'text'), linkResolver)}</div>}
                {link !== '/undefined' && (
                  <div className='mt-5 sm:mt-8 sm:flex sm:justify-center'>
                    <div>
                      {get(slice.primary.button_link, 'url') ? (
                        <a className='rounded-md font-semibold shadow-lg flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-semibold uppercase transform duration-300 ease-in-out transition focus:outline-none focus:shadow-outline md:px-10 md:py-4 md:text-xl bg-white text-teal-600 hover:text-teal-700 hover:bg-opacity-75' href={link}>{get(slice.primary, 'button_text')}</a>
                      ):(
                        <Link className='rounded-md font-semibold shadow-lg flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-semibold uppercase transform duration-300 ease-in-out transition focus:outline-none focus:shadow-outline md:px-10 md:py-4 md:text-xl bg-white text-teal-600 hover:text-teal-700 hover:bg-opacity-75' to={link}>{get(slice.primary, 'button_text')}</Link>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {slice.primary.image && (
              <div className='flex-1'>
                <div className=''>
                  <img className='h-auto w-full object-cover object-top sm:h-72 md:h-96 md:w-full md:h-full' src={slice.primary.image.url} alt={slice.primary.image.alt} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

Hero.propTypes = {
  slice: PropTypes.object.isRequired,
  publishDate: PropTypes.string,
  author: PropTypes.object
}

Hero.defaultProps = {
  publishDate: null,
  author: null,
}

export default Hero
