import React from 'react'
import PropTypes from 'prop-types'
import BannerWithCaption from '../components/bannerWithCaption'
import CardDeck from '../components/cardDeck'
import Hero from '../components/hero'
import Image from '../components/image'
import Quote from '../components/quote'
import Text from '../components/text'
import TextWithButton from '../components/textWithButton'
import Video from '../components/video'

const SliceList = ({ slice }) => {

  return (
    <React.Fragment>
      {slice.type === 'banner_with_caption' && (
        <div className='max-w-screen-xl mx-auto py-8 px-4 sm:px-6 md:py-16 lg:p-8 relative'>
          <BannerWithCaption slice={slice} />
        </div>
      )}
      {slice.type === 'card_deck' && (
        <div className='max-w-screen-xl mx-auto py-8 px-4 sm:px-6 md:py-16 lg:p-8 relative'>
          <CardDeck slice={slice} />
        </div>
      )}
      {slice.type === 'hero' && (
        <Hero slice={slice} />
      )}
      {slice.type === 'image' && (
        <div className='max-w-screen-xl mx-auto py-8 px-4 sm:px-3 md:py-6 lg:px-4 lg:py-8 relative'>
          <Image slice={slice} />
        </div>
      )}
      {slice.type === 'quote' && (
        <div className='bg-blue-200 z-10'>
          <div className='max-w-6xl mx-auto py-8 px-12 sm:px-6 md:py-16 lg:px-8 relative'>
            <Quote slice={slice} />
          </div>
        </div>
      )}
      {slice.type === 'text' && (
        <div className='max-w-6xl mx-auto py-8 px-4 sm:px-6 md:py-16 lg:p-8 relative'>
          <Text slice={slice} />
        </div>
      )}
      {slice.type === 'text_with_button' && (
        <div className='max-w-6xl mx-auto py-8 px-4 sm:px-6 md:py-16 lg:p-8 relative'>
          <TextWithButton slice={slice} />
        </div>
      )}
      {slice.type === 'video' && (
        <div className='max-w-screen-xl mx-auto py-8 px-4 sm:px-3 md:py-6 lg:px-4 lg:py-8 relative'>
          <Video slice={slice} />
        </div>
      )}
    </React.Fragment>
  )
}

SliceList.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default SliceList
