import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get';
import { Link } from 'gatsby'
import { RichText } from 'prismic-reactjs';
import { linkResolver } from '../utils/linkResolver';
import ResponsiveImage from '../components/responsiveImage'

const BannerWithCaption = ({ slice }) => {
  var link = get(slice.primary.button_link, 'url',(linkResolver(get(slice.primary.button_link, '_meta',[]),[])))

  return (
    <React.Fragment>
      <svg className='blob absolute max-h-screen top-0 z-0 text-blue-500' viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
        <path fill="currentColor" fillOpacity="0.3" d="M31.1,-28.2C44.2,-26.2,61.3,-20,63.9,-10.5C66.5,-1,54.6,11.8,43.1,18.5C31.7,25.1,20.6,25.6,8.8,34.2C-2.9,42.8,-15.4,59.6,-29.2,62.3C-43,65,-58.3,53.8,-61.6,39.6C-64.9,25.5,-56.3,8.5,-52.9,-9C-49.4,-26.5,-51,-44.6,-43,-47.6C-35.1,-50.5,-17.5,-38.3,-4.2,-33.3C9,-28.2,18.1,-30.3,31.1,-28.2Z" transform="translate(100 100)" />
      </svg>
      <div className={`component-${slice.type} relative overflow-hidden shadow-sm rounded-md bg-white`}>
        <div className='wrapper pb-6 sm:pb-8 lg:max-w-2xl lg:w-1/2 lg:pb-10'>
          <div className='caption mt-10 mx-auto max-w-screen-xl px-6 sm:mt-8 sm:text-center lg:mt-10 lg:text-left'>
            <div className='title'><h1 className='text-blue-800'>{RichText.asText(get(slice.primary, 'title'), linkResolver)}</h1></div>
            <div className='text leading-6 mt-3 mb-3 sm:mt-5 sm:max-w-xl sm:mx-auto md:mt-5 lg:mx-0'>{RichText.render(get(slice.primary, 'text'), linkResolver)}</div>
            {link !== '/undefined' && (
              <div className='button-wrapper mt-3 sm:mt-5 md:flex md:justify-center lg:justify-start'>
                {get(slice.primary.button_link, 'url') ? (
                  <a className='button link' href={link}>{get(slice.primary, 'button_text')}</a>
                ):(
                  <Link className='button link' to={link}>{get(slice.primary, 'button_text')}</Link>
                )}
              </div>
            )}
          </div>
        </div>

        <div className='banner lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 bg-gray-300'>
          <div className='image-wrapper relative overflow-hidden lg:object-cover h-full w-full'>
            <ResponsiveImage image={get(slice.primary, 'image')} sizes='(min-width: 640px) calc(100vw - 3rem), (min-width: 1024px) calc(50vw - 4rem), (min-width: 1280px) 608px, calc(100vw - 2em)' />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

BannerWithCaption.propTypes = {
  slice: PropTypes.object.isRequired,
}

export default BannerWithCaption
